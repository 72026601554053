import React, { Component } from 'react';
import Header from '../header/Header';
import HeaderPrimo from '../header/HeaderPrimo';
import catalogue from '../../data/2019_Automne.json';
//import primo from '../../data/Primo2.json';
import Monuments from "../monuments/Monuments";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

class Liste extends Component {

    primo

    constructor(props) {
        super(props);
           
        this.state = {
            data : null,
            active : null,
            loading: false,
            loaded: false
        };
    }

    async getJsonContent() {
        const code = window.gpgCatalogueParams.split("=")[1];
        await fetch(process.env.REACT_APP_API_V4_PRIMONUMENTS, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + code
            }
        }).then(response => {
            return response.text();
        }).then((text) => {
            var data = JSON.parse(text).json_content.json_content 
            this.primo = JSON.parse(data)

            // On cherche l'index si celui-ci est spécifié dans l' URL
            let index = this.props.type ==="Primo"?
            this.primo.tabs.findIndex(r =>r.tab === this.props.match.params.tab):
            catalogue.recettes.findIndex(r =>r.taille === this.props.match.params.tab);  
            // Si l'index n'est pas trouvé on prend celui par défaut le premier pour les couleurs et le 100x200 pour les recettes
            index =  index !==-1 ? index: this.props.type === "Primo" ? 0:1;   

            this.setState({
                data : this.props.type === "Primo" ? this.primo.tabs[index] : catalogue.recettes[index],
                active : index,
                loading: false
            })
        }).catch();
    }

    async componentDidMount(){
        await this.getJsonContent()
        this.changeClass();
        this.setState({loaded:true})
    }

    changeClass=(data=null)=>{
        const colorClass = this.props.type === "Primo" ? data ? data.tab:this.state.data.tab:null;
        document.body.className="";
        if (colorClass){
            document.body.classList.add(colorClass);
        }
    }

    getTabs() {
        if (this.props.type === "Primo"){
            return this.primo.tabs.map(r => {return r.tab;})
        }
        else{
            return catalogue.recettes.map(r => {return r.taille;})
        }
    }

    changeTab(index) {
        const data = this.props.type === "Primo" ? this.primo.tabs[index] : catalogue.recettes[index];
        this.setState({data : data});
        this.setState({active : index});
        this.setState({ loading: true });
        this.changeClass(data);
        setTimeout(() => {
            this.setState({loading: false});
        }, 700);
    }

    render() {
        if(this.state.loaded == false) {
            return (<div>Loading...</div>)
        }
        const { loading } = this.state;
        const { type } = this.props;
        return (
            <div className="App">
                <div>  
                    {type=== "Primo"&& (<HeaderPrimo/>)}
                    {type!== "Primo"&& (<Header/>)}

                    {  <nav className="menuRecettes">
                        <ul>
                            {this.getTabs().map((r, i) => {
                                let className;
                                if(this.state.active === i){
                                    className='active'
                                }
                                    // eslint-disable-next-line
                                    return <li key={i} className={className} onClick={this.changeTab.bind(this, i)}><a>Monuments {r}</a><span><em></em></span></li>
                                }
                            )}

                        </ul>
                    </nav>}
 
                <section className="contentHome_Main">

                    <LoadingOverlay className="content">
                        <Monuments monuments={this.state.data} type={type}/>
                        <Loader loading={loading}/>
                    </LoadingOverlay>

                </section>
               </div>
                <div style={{marginTop: '-15px', fontSize: '0.8em', display: 'block', width: '100%'}}><p style={{float: 'right', marginRight: '20px',marginTop: '12px'}}>
                    **Prix hors semelle, hors accessoire, hors motif, hors gravure, hors transport.</p></div>

            </div>
        );
    }
}

export default Liste;
