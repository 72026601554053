import React, { Component } from 'react';
import { Link } from "react-router-dom";
import selection from '../../data/2019_Automne.json';
//import primo from '../../data/Primo.json';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ScrollableAnchor from 'react-scrollable-anchor';
import LazyLoad from 'react-lazy-load';

class Detail extends Component {
    
    primo

    constructor(props) {
        super(props);
        this.state = {
            monuments : null,
            data : null,
            image : null,
            isOpen: false,
            tab: {tab:null}, //renvoi le tab actif sur retour, choix de la bonne classe dans DidMount
            minOrder: null,
            maxOrder: null,
            loaded: false
        }
    }

    async getJsonContent() {
        const code = window.gpgCatalogueParams.split("=")[1];
        await fetch(process.env.REACT_APP_API_V4_PRIMONUMENTS, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + code
            }
        }).then(response => {
            return response.text();
        }).then((text) => {
            var data = JSON.parse(text).json_content.json_content 
            this.primo = JSON.parse(data)
            data = this.props.type === "Primo" ? this.primo.tabs :selection.recettes;
            const element = this.props.type ==="Primo"?
            data.find(r =>r.tab === this.props.match.params.tab):
            data.find(r =>r.taille === this.props.match.params.tab);
            const monuments = element.monuments.sort(function(a, b){
                return a.ordre - b.ordre;
            });

            //console.log(monuments)
            const tab = this.props.type ==="Primo"? element.tab : element.taille;
            const monument = element.monuments.find(m =>
                m.modele === this.props.match.params.monument && m.ordre === parseInt(this.props.match.params.ordre));
            
            this.setState({
                monuments : monuments,
                data : monument,
                image : monument.images[0],
                isOpen: false,
                tab: {tab:tab}, //renvoi le tab actif sur retour, choix de la bonne classe dans DidMount
                minOrder: monuments[0].ordre,
                maxOrder: monuments[monuments.length-1].ordre,
            })
        }).catch();
    }

    async componentDidMount() {
        await this.getJsonContent()
        const colorClass = this.props.type === "Primo" ? this.state.tab.tab:null;
        document.body.className="";
        if (colorClass){
            document.body.classList.add(colorClass);
        }
        this.setState({loaded:true})
    }

    showPriceHead(type) {
        const headers = [];
        let subheaders = "";
        const span = type==="Primo"?2:1;

        for(let i=0; i<this.state.data.prixVente.length; i++){
            let tmp = '';
            for(let j=0; j<this.state.data.prixVente[i].granites.length; j++){
                tmp += this.state.data.prixVente[i].granites[j] + '<br />';
            }
            headers.push({__html : tmp});
        }
        if (type==="Primo"){
            headers.forEach((h) => { subheaders += "<th>Prix au détail par monument pour l'achat de 2 monuments</th><th>Prix au détail par monument pour l'achat de 4 monuments</th>"});
        }

        return  (
        <thead>
            <tr>
                <th rowSpan ={span}>&nbsp;</th>
                <th rowSpan ={span}>Poids</th>
                {headers.map((h, i) => <th colSpan={span} key={i} dangerouslySetInnerHTML={h} />)}
            </tr> 
            {type === "Primo" && <tr dangerouslySetInnerHTML={{__html : subheaders}} />}
        </thead>
        );
    }

    showPriceBody(type) {
        const datas = [];
        if (type === "Primo"){

            this.state.data.prixVente[0].prix.forEach(p => {
                if (p.poids){
                    datas.push([{__html: 'Soubassement ' + p.soubassement +'<br /> Tombale ' + p.tombale +'<br /> Recette ' + p.taille}, {__html: p.poids + ' kg'}]);
                }  
            });
            for(let i=0; i<this.state.data.prixVente.length; i++){
                const thePrices = this.state.data.prixVente[i].prix.filter(item=>item.poids);
                thePrices.forEach((p, key) => {
                   p.prix2 && p.prix2!=="null" && p.prix2!== 0? datas[key].push({__html: p.prix2 + ' €'}):datas[key].push({__html: ''});
                   p.prix4 && p.prix4 !=="null" && p.prix4!== 0? datas[key].push({__html: p.prix4 + ' €'}):datas[key].push({__html: ''});
                });
            }
        }else{
            this.state.data.prixVente[0].prix.forEach(p => {
                if (p.soubassement){
                    const stInfo = this.state.data.dimension_20 ? p.soubassement === 20 ? '<br /> ' + this.state.data.dimension_20 :'<br /> ' + this.state.data.dimension :"";
                    const firstCol = 'Soubassement ' + p.soubassement +'<br /> Tombale ' + p.tombale + stInfo;
                    datas.push([{__html: firstCol}, {__html: p.poids + ' kg'}]);
                }
            });
            for(let i=0; i<this.state.data.prixVente.length; i++){
                this.state.data.prixVente[i].prix.forEach((p, key) => {
                    if (p.soubassement){
                        p.prix[0] && p.prix !==0 ? datas[key].push({__html: p.prix[0] + ' €'}):datas[key].push({__html: ''});
                    }
                });
            }
        }

        return <tbody>
            {datas.map((data,idx) =>
                <tr key={idx}>
                    {data.map((d,index) => <td dangerouslySetInnerHTML={d} key={index} />)}
                </tr>
            )}
        </tbody>
    }

    changeImage(image) {
        this.setState({image : image});
    }

    goToNextMonument(actual,addUrl) {
        if(actual === this.state.maxOrder) return;

        let next = this.state.monuments.find(m =>
            m.ordre === actual+1);
       if (next){
            this.setState({data: next});
            this.setState({image: next.images[0]});
            this.props.history.push('/detail/'+this.props.match.params.tab+'/'+next.modele+'/'+next.ordre+addUrl);
       }
    }

    goToPrevMonument(actual,addUrl) {
        if(actual === this.state.minOrder) return;

        let prev = this.state.monuments.find(m =>
        m.ordre === actual-1);
        if (prev) {
            this.setState({data: prev});
            this.setState({image: prev.images[0]});
            this.props.history.push('/detail/'+this.props.match.params.tab+'/'+prev.modele+'/'+prev.ordre+addUrl);
        }
    }

    render() {
        if(this.state.loaded == false) {
            return (<div>Loading...</div>)
        }
        const {type} = this.props;
        const label =  type === "Primo" ? "Couleur" : "Recette";
        const image = this.state.image && require('../../images/full/'+ this.state.image);
        const imageFullscreen = this.state.image && require('../../images/full/'+ this.state.image);
        const { isOpen } = this.state;
        const className = type === "Primo" ? "" : this.state.data && this.state.data.tags[0];
        const titre = this.state.data && this.state.data.designation.split("-")[0];
        const stele = this.state.data && this.state.data.designation.split("-")[1];
        const motif = this.state.data && this.state.data.Motif ? ` Motif: ${this.state.data.Motif} =>${this.state.data.Prix.Motif} €`:"";
        const order = this.state.data && this.state.data.ordre;
        const sliderNEXT = order < this.state.maxOrder ? 'sliderNEXT active' : 'sliderNEXT';
        const sliderPREV = order > this.state.minOrder ? 'sliderPREV active' : 'sliderPREV';
        const customStyles = {
            content : {
                backgroundColor : 'white',
            }
        };
        const addUrl = type === "Primo" ? "/?type_app=Primo":"";
        const mainTab = type === "Primo" ? "Primo":"Automne 2019";

        return (
            <main className="Detail">
              <div>
                <section className="top_Main">
                    <Link to={`/tab/${this.state.tab.tab}${addUrl}`} className="navDetails"  href="" title=""><span></span>Retour</Link>
                    <div className="breadcrump">{`Extranet > Catalogue > ${mainTab} > ${label} ${this.props.match.params.tab} > ${this.state.data.designation.split("-")[0]}`}</div>
                </section>

                <section className="contentDetails_Main">
                    <div className="content">
                        <div className="lesDetails">
                            <div className="navSlider">
                                <div onClick={()=>this.goToPrevMonument( order,addUrl)} className={sliderPREV}><button></button></div>
                                <div onClick={()=>this.goToNextMonument( order,addUrl)} className={sliderNEXT}><button></button></div>
                            </div>

                            <div className="vignettes">
                                {type !== "Primo" && this.state.data.images.map((img, i) => {
                                    const image = require('../../images/full/'+img);
                                    return <LazyLoad height={75}  key={i} className="zoom" style={{ backgroundColor: 'white' }} title=""><img onClick={this.changeImage.bind(this, img)} src={image} alt="GPG Granit" /></LazyLoad>
                                    }
                                )}
                                <a className="loupe" onClick={() => this.setState({ isOpen: true })}><span></span>Zoom</a>
                            </div>

                            <div className="visuel">
                                <div><img src={image} alt="GPG Granit" onClick={() => this.setState({ isOpen: true })} /></div>
                            </div>

                            <div className="infos">
                                <p className="titre">{titre}</p>
                                <p className="sstitre">{stele}<span className="motif">{motif}</span></p>
                                <p className="couleur">{this.state.data.defaut.granite}</p>
                                <span className={className}></span>
                                <p className="reference">Réf. produit :<span>{this.state.data.modele}</span></p>
                                <p className="stele">Stèle :<span>{this.state.data.dimension}</span></p>
                                {type !== "Primo" && <div>
                                    {window.gpgCatalogueParams && window.config3d_host && (
                                        <a className="config" onClick={() => window.location = window.config3d_host + '/monument/'+ this.state.data.refConf3D +'/?' + window.gpgCatalogueParams} ></a>
                                    )}
                                    {window.gpgCatalogueParams && !window.config3d_host && (
                                        <a className="config" onClick={() => window.location = 'https://config3d.extranet.gpggranit.com/monument/'+ this.state.data.refConf3D +'/?' + window.gpgCatalogueParams} ></a>
                                    )}
                                    {!window.gpgCatalogueParams && (
                                        <a className="config" onClick={() => window.location = 'https://config3d.extranet.gpggranit.com/monument/'+ this.state.data.refConf3D } ></a>
                                    )}
                                 </div>}


                            </div>

                            {isOpen && (
                                <Lightbox
                                    mainSrc={imageFullscreen}
                                    style={customStyles}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                />
                            )}

                        </div>

                        <ScrollableAnchor id={'tarif'}>
                            <div className="tabDetails">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    {this.showPriceHead(type)}
                                    {this.showPriceBody(type)}
                                </table>
                            </div>
                        </ScrollableAnchor>
                    </div>

                    <div style={{marginTop: '15px', fontSize: '0.8em', display: 'block', width: '100%'}}><p style={{float: 'right', marginRight: '20px'}}>**Prix hors semelle, hors accessoire, hors motif, hors gravure, hors transport.</p></div>
                </section>
             </div>
            </main>
        );
    }
}

export default Detail;